import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-exercise-log-dialog',
  templateUrl: './exercise-log-dialog.component.html',
  styleUrls: ['./exercise-log-dialog.component.scss']
})
export class ExerciseLogDialogComponent implements OnInit {
  exerciseForm: FormGroup;
  
  predefinedExercises = [
    'Walking', 'Running', 'Swimming', 'Yoga', 'Meditation',
    'Cycling', 'Strength Training', 'Other'
  ];

  predefinedLocations = [
    'Home', 'Gym', 'Park', 'Beach', 'Office', 'Other'
  ];

  predefinedDurations = [
    5, 10, 15, 20, 30, 45, 60
  ];

  customLocation: boolean = false;
  customDuration: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExerciseLogDialogComponent>
  ) {
    this.exerciseForm = this.fb.group({
      exerciseType: ['', Validators.required],
      location: ['', Validators.required],
      customLocationInput: [''],
      duration: ['', Validators.required],
      customDurationInput: ['']
    });
  }

  ngOnInit() {}

  onLocationChange(value: string) {
    this.customLocation = value === 'Other';
    if (!this.customLocation) {
      this.exerciseForm.get('customLocationInput')?.setValue('');
    }
  }

  onDurationChange(value: number) {
    this.customDuration = value === 0;
    if (!this.customDuration) {
      this.exerciseForm.get('customDurationInput')?.setValue('');
    }
  }

  onSubmit() {
    if (this.exerciseForm.valid) {
      const formValue = this.exerciseForm.value;
      const result = {
        exerciseType: formValue.exerciseType,
        location: this.customLocation ? formValue.customLocationInput : formValue.location,
        duration: this.customDuration ? formValue.customDurationInput : formValue.duration
      };
      this.dialogRef.close(result);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}