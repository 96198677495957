<div class="ion-page">
    <ion-content>
        <div class="fullscreen beige-space">
            <div class="box">
                <div class="back" (click)="onNoClick()">
                    <mat-icon>close</mat-icon>
                </div>
                <h1 class="title flex">Make it your year with Contemplate
                    <div class="ml-auto align-self-end pr-4"></div>
                </h1>
                <div class="currentContent-section grey-space flex-1 overflow-auto">

                    <div *ngIf="loadingSubscription" class="p-5 d-flex content-center">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>

                    <div *ngIf="!loadingSubscription" class="d-flex w-100 login-form text-start flex-column">

                        <!-- Start Trial -->
                        <ng-container
                            *ngIf="!subscriptionData.subscriptionState.IsMember && !subscriptionData.affiliationCode">
                            <button class="btn btn-success w-100 text-white" type="button" (click)="startTrial()">
                                <span *ngIf="subscribing" class="spinner-border spinner-border-lg" role="status"
                                    aria-hidden="true"></span>
                                <span *ngIf="!subscribing">TRY WEEK 1 FOR FREE<br><small>(No credit card
                                        required)</small></span>
                            </button>
                        </ng-container>

                        <ng-container
                            *ngIf="subscriptionData.subscriptionState.IsMember || subscriptionData.affiliationCode">
                            <ng-container *ngIf="subscriptionData.membership.Offers.length == 0">
                                <h2>No offers found</h2>
                            </ng-container>

                            <ng-container *ngIf="!isMobile && subscriptionData.membership.Offers.length > 0">
                                <div class="offer-list">
                                    <ng-container *ngFor="let offer of subscriptionData.membership.Offers">
                                        <!-- [class.selected]="selectedOffer && selectedOffer.MembershipOfferId == offer.MembershipOfferId" -->
                                        <div class="offer-card" (click)="selectOffer(offer)">
                                            <div class="option">
                                                <div class="circle"
                                                    *ngIf="selectedOffer && selectedOffer.MembershipOfferId == offer.MembershipOfferId">
                                                </div>
                                            </div>
                                            <div class="offer-detail">
                                                <ng-container *ngIf="offer.BillingCycle == 'annual'">
                                                    <h2 class="mt-2" *ngIf="subscriptionData.trialRemainingDays > 0">
                                                        Free for {{ subscriptionData.trialRemainingDays }} <ng-container
                                                            *ngIf="subscriptionData.trialRemainingDays == 1">day</ng-container>
                                                        <ng-container
                                                            *ngIf="subscriptionData.trialRemainingDays > 1">days</ng-container>,
                                                        then <span
                                                            [class.strikeout]="subscriptionData.discountedPrice >= 0">{{
                                                            offer.Amount | currency : offer.Currency :'symbol':'1.2-2'
                                                            }}/year</span> <span
                                                            *ngIf="subscriptionData.discountedPrice >= 0"> {{
                                                            subscriptionData.discountedPrice | currency : offer.Currency
                                                            :'symbol':'1.2-2' }}/year</span></h2>

                                                    <h2 class="mt-2" *ngIf="subscriptionData.trialRemainingDays <= 0">
                                                        <span
                                                            [class.strikeout]="subscriptionData.discountedPrice >= 0">{{
                                                            offer.Amount | currency : offer.Currency :'symbol':'1.2-2'
                                                            }}/year</span> <span
                                                            *ngIf="subscriptionData.discountedPrice >= 0"> {{
                                                            subscriptionData.discountedPrice | currency : offer.Currency
                                                            :'symbol':'1.2-2' }}/year</span></h2>

                                                    <!-- <p class="mb-0">{{ offer.Amount / 12 | currency : offer.Currency :'symbol':'1.2-2' }} per month / billed annually</p> -->
                                                </ng-container>
                                                <ng-container *ngIf="offer.BillingCycle == 'month'">
                                                    <h2 class="mt-2 " *ngIf="subscriptionData.trialRemainingDays > 0">
                                                        Free for {{ subscriptionData.trialRemainingDays }} <ng-container
                                                            *ngIf="subscriptionData.trialRemainingDays == 1">day</ng-container>
                                                        <ng-container
                                                            *ngIf="subscriptionData.trialRemainingDays > 1">days</ng-container>,
                                                        then <span
                                                            [class.strikeout]="subscriptionData.discountedPrice >= 0">{{
                                                            offer.Amount | currency : offer.Currency :'symbol':'1.2-2'
                                                            }}/month</span> <span
                                                            *ngIf="subscriptionData.discountedPrice >= 0"> {{
                                                            subscriptionData.discountedPrice | currency : offer.Currency
                                                            :'symbol':'1.2-2' }}/month</span></h2>

                                                    <h2 class="mt-2 mb-0"
                                                        *ngIf="subscriptionData.trialRemainingDays <= 0"><span
                                                            [class.strikeout]="subscriptionData.discountedPrice >= 0">{{
                                                            offer.Amount | currency : offer.Currency :'symbol':'1.2-2'
                                                            }}/month</span> <span
                                                            *ngIf="subscriptionData.discountedPrice >= 0"> {{
                                                            subscriptionData.discountedPrice | currency : offer.Currency
                                                            :'symbol':'1.2-2' }}/month</span></h2>

                                                    <!-- <p class="mb-0">{{ offer.Amount | currency : offer.Currency :'symbol':'1.2-2' }} per month </p> -->
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="subscriptionData.subscriptionState.IsMember && subscriptionData.subscriptionState.ConsumedTrial && subscriptionData.subscriptionState.IsTrial">

                            </ng-container>
                            <ng-container
                                *ngIf="subscriptionData.subscriptionState.IsMember && subscriptionData.subscriptionState.ConsumedTrial && !subscriptionData.subscriptionState.IsSubscribed">

                            </ng-container>
                            <ng-container
                                *ngIf="subscriptionData.subscriptionState.IsMember && subscriptionData.subscriptionState.ConsumedTrial && subscriptionData.subscriptionState.IsSubscribed">

                            </ng-container>


                            <form autocomplete="off" class="grey-space login-form px-0" [formGroup]="form"
                                (ngSubmit)="submitPassword()">
                                <mat-label class="mt-0 mb-4 dflex place-start" style="opacity: 0.7;">Do you have an Affiliation Code?</mat-label>
                                <mat-form-field class="w-100 mb-0" appearance="outline">
                                    <input matInput formControlName="JoiningCode" type="text" [disabled]="loading"
                                        placeholder="Enter your affiliation code">
                                </mat-form-field>
                                <div class="remember-me">
                                    <div class="error-label" *ngIf="error">
                                        <mat-error>{{ error }}</mat-error>
                                    </div>
                                </div>

                                <div class="action mt-0 text-center">

                                    <button class="btn btn-primary dflex place-center align-center text-white w-100" 
                                        type="submit"
                                        [disabled]="form.invalid"
                                        (click)="submitPassword()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>&nbsp;
                                        <span *ngIf="loading" class="sr-only">Loading...</span>
                                        <span *ngIf="!loading">Apply Code</span>
                                    </button>

                                    <!-- <app-adios-btn class="action-button" highlight="teal" [addmargin]="false"
                                        [addmarginleft]="false" (click)="submitPassword()">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>&nbsp;
                                        <span *ngIf="loading" class="sr-only">Loading...</span>
                                        <span *ngIf="!loading">Apply Code</span>
                                    </app-adios-btn> -->
                                </div>
                            </form>


                            <div *ngIf="subscriptionData.affiliationCode" class="message my-2 text-muted">
                                <p>Affiliation Code Applied: {{ subscriptionData.affiliationCode.Code }}. You can now subscribe to the program.
                                    <span *ngIf="subscriptionData.affiliationCode.OrganisationId">This code will associate
                                        you with {{ subscriptionData.affiliationCode.Organisation.Name }}.</span>
                                </p>
                                <!-- <div class="description-box" *ngIf="subscriptionData.membership">
                                    <h2 *ngIf="subscriptionData.membership">{{subscriptionData.membership.Name}}</h2>
                                    <div *ngIf="subscriptionData.membership.Offerings" [innerHtml]="subscriptionData.membership.Offerings"></div>
                                </div> -->
                            </div>
    
                            <button *ngIf="!isMobile" class="btn btn-success text-white w-100 text-dark my-3"
                                type="button" (click)="subscribePlan()">
                                <span *ngIf="subscribing" class="spinner-border spinner-border-lg" role="status"
                                    aria-hidden="true"></span>
                                <span *ngIf="!subscribing">SUBSCRIBE NOW</span>
                            </button>

                            <div class="text-center text-muted" *ngIf="!isMobile">
                                <small *ngIf="selectedOffer && selectedOffer.BillingCycle == 'annual'">The annual
                                    subscription is <span [class.strikeout]="subscriptionData.discountedPrice >= 0">{{
                                        selectedOffer.Amount | currency : selectedOffer.Currency :'symbol':'1.2-2'
                                        }}</span> <span *ngIf="subscriptionData.discountedPrice >= 0"> {{
                                        subscriptionData.discountedPrice | currency : selectedOffer.Currency
                                        :'symbol':'1.2-2' }}</span> and automatically renews each year.</small>
                                <small *ngIf="selectedOffer && selectedOffer.BillingCycle == 'month'">The monthly
                                    subscription is <span [class.strikeout]="subscriptionData.discountedPrice >= 0">{{
                                        selectedOffer.Amount | currency : selectedOffer.Currency :'symbol':'1.2-2'
                                        }}</span> <span *ngIf="subscriptionData.discountedPrice >= 0"> {{
                                        subscriptionData.discountedPrice | currency : selectedOffer.Currency
                                        :'symbol':'1.2-2' }}</span> and automatically renews each month.</small>
                                <small *ngIf="subscriptionData.trialOrder"><br>Your 7 free trial is on {{
                                    subscriptionData.trialOrder.CurrentPeriodEnd | date: 'medium' }}</small>
                            </div>

                        </ng-container>

                        <!-- <div class="order-list mt-5" *ngIf="subscriptionData.ongoingOrders.length > 0">
                            <div class="order-card" *ngFor="let order of subscriptionData.subscriptionState.Orders">
                                <h1>{{ order.Order.OrderReference }} <small>{{ order.Order.OrderSource }}</small></h1>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>
    </ion-content>
</div>