import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-mark-complete-dialog',
    template: `
        <div class="dialog-container">
            <h2 mat-dialog-title class="dialog-title">
                <mat-icon class="title-icon">task_alt</mat-icon>
                Mark Assignment Complete
            </h2>
            
            <mat-dialog-content class="dialog-content">
                <mat-label class="place-start">Completion Remarks</mat-label>
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <textarea matInput [(ngModel)]="remarks" 
                              rows="4" 
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="4"
                              cdkAutosizeMaxRows="8"
                              placeholder="Please provide any relevant comments or notes about completing this assignment"></textarea>
                    <mat-hint>Add any important details about the completion</mat-hint>
                </mat-form-field>
            </mat-dialog-content>

            <mat-dialog-actions align="end" class="dialog-actions">
                <button mat-button 
                        (click)="onCancel()"
                        class="cancel-button">
                    Cancel
                </button>
                <button mat-button color="primary" 
                        [disabled]="!remarks?.trim()" 
                        (click)="onSubmit()"
                        class="submit-button">
                    Mark Complete
                </button>
            </mat-dialog-actions>
        </div>
    `,
    styles: [`

        .dialog-container {
            padding: 24px 12px;
        }
    
        .dialog-title {
            margin: 0 0 20px 0;
            display: flex;
            align-items: center;
        }

        .title-icon {
            margin-right: 8px;
            vertical-align: middle;
        }

        .dialog-content {
            margin-bottom: 20px;
        }

        .w-100 {
            width: 100%;
        }

        .dialog-actions {
            margin: 0 20px 0px 20px;
            padding: 0;
        }

        .cancel-button {
            margin-right: 8px;
        }

        .submit-button {
            min-width: 120px;
        }

        ::ng-deep .mat-form-field-wrapper {
            margin-bottom: -1.25em;
        }
    `]
})
export class MarkCompleteDialogComponent {
    remarks: string = '';

    constructor(
        public dialogRef: MatDialogRef<MarkCompleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.dialogRef.close({ remarks: this.remarks });
    }
} 