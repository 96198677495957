import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';

interface Assignment {
    type: string;
    selectedItems?: number;
    selectedContentIds?: number[];
    dueDate: Date;
    title: string;
    description: string;
    recurring: {
        enabled: boolean;
        frequency: 'daily' | 'weekly' | 'monthly';
        repeatEvery: number;
        endDate?: Date;
    };
}

interface ModuleContent {
    ContentId: number;
    ContentName: string;
}

interface Module {
    ProgramLessonId: number;
    ChapterName: string;
    Contents: ModuleContent[];
}

@Component({
    selector: 'sb-add-assignments-modal',
    templateUrl: './add-assignments-modal.component.html',
    styleUrls: ['./add-assignments-modal.component.scss']
})
export class AddAssignmentsModalComponent implements OnInit {
    @Input() participantId?: number;
    @Input() participant?: any;

    loading = false;
    participants: any[] = [];
    selectedParticipantId?: any[];

    // assignmentTypes = ['Assessment', 'Module', 'Exercise', 'Game Movie', 'Other'];
    assignmentTypes = ['Module', 'Assessment', 'Other'];
    assignments: Assignment[] = [];

    // Cache for items by type
    private itemsCache: { [key: string]: any[] } = {};

    // Add new properties
    moduleContents: { [moduleId: number]: any[] } = {};

    // Add new properties to store processed modules
    private modulesByLesson: { [key: number]: Module } = {};

    constructor(
        private modalController: ModalController,
        private apiService: ApiService,
        private snackbar: MatSnackBar
    ) { }

    ngOnInit() {
        if (this.participant) {
            this.selectedParticipantId = [this.participant.UserId];
        } else {
            this.loadParticipants();
        }
        this.addNewAssignment();

    }

    async loadParticipants() {
        this.apiService.getTrainerParticipantsList().then((response: any) => {
            this.participants = response.data;
        }).catch((error: any) => {
            this.snackbar.open('Error loading participants', 'Close', { duration: 3000 });
        });
    }

    onParticipantChange($event: any) {
        console.log($event);
        console.log(this.selectedParticipantId.length, this.participants.length);

        // if ($event.value.indexOf('0') !== -1) {
        //     this.selectedParticipantId = this.participants.map(p => p.Id);
        // } else {
        //     this.selectedParticipantId = $event.value;
        // }
    }

    selectAllParticipants() {

        if (this.selectedParticipantId.length === this.participants.length) {
            this.selectedParticipantId = [];
        } else {
            this.selectedParticipantId = [0, ...this.participants.map(p => p.Id)];
        }
    }

    addNewAssignment() {
        this.assignments.push({
            type: '',
            // selectedItems: ,
            dueDate: new Date(),
            title: '',
            description: '',
            recurring: {
                enabled: false,
                frequency: 'daily',
                repeatEvery: 1,
            }
        });
    }

    removeAssignment(index: number) {
        this.assignments.splice(index, 1);
        if (this.assignments.length === 0) {
            this.addNewAssignment();
        }
    }

    async onTypeChange(index: number) {
        const type = this.assignments[index].type;
        if (!this.itemsCache[type]) {
            try {
                this.loading = true;
                const response = await this.loadItemsByType(type);
                switch (type.toLowerCase()) {
                    case 'assessment':
                        this.itemsCache[type] = response.data.students.map((item: any) => ({
                            id: item.AssessmentId,
                            name: item.Title
                        }));
                        break;
                    case 'module':
                        // Process the module list response
                        const modules = this.processModuleResponse(response.data);
                        this.itemsCache[type] = Object.values(modules).map(module => ({
                            id: module.ProgramLessonId,
                            name: module.ChapterName
                        }));
                        break;
                    case 'exercise':
                        this.itemsCache[type] = response.data.map((item: any) => { return { id: item.ExerciseId, name: item.Title } });
                        break;
                    default:
                        this.itemsCache[type] = response.data.map((item: any) => { return { id: item.id, name: item.name } });
                        break;
                }
                console.log(this.itemsCache);
            } catch (error) {
                this.snackbar.open(`Error loading ${type} items`, 'Close', { duration: 3000 });
            } finally {
                this.loading = false;
            }
        }
    }

    private processModuleResponse(data: any[]): { [key: number]: Module } {
        const modules: { [key: number]: Module } = {};
        
        data.forEach(item => {
            if (!modules[item.ProgramLessonId]) {
                modules[item.ProgramLessonId] = {
                    ProgramLessonId: item.ProgramLessonId,
                    ChapterName: item.ChapterName,
                    Contents: []
                };
            }
            
            // Add content if it doesn't already exist
            const contentExists = modules[item.ProgramLessonId].Contents.some(
                c => c.ContentId === item.ContentId
            );
            
            if (!contentExists) {
                modules[item.ProgramLessonId].Contents.push({
                    ContentId: item.ContentId,
                    ContentName: item.ContentName
                });
            }
        });

        this.modulesByLesson = modules;
        return modules;
    }

    getModuleContents(moduleId: number): ModuleContent[] {
        return this.modulesByLesson[moduleId]?.Contents || [];
    }

    getItemsByType(type: string): any[] {
        return this.itemsCache[type] || [];
    }

    private async loadItemsByType(type: string): Promise<any> {
        switch (type.toLowerCase()) {
            case 'assessment':
                return this.apiService.getTrainerAssessments();
            case 'module':
                return this.apiService.getTrainerModules();
            case 'exercise':
                return this.apiService.getTrainerExercises();
            case 'content':
            // return this.apiService.getContent();
            case 'adioscope':
            // return this.apiService.getAdioscope();
            default:
            // return this.apiService.getOtherItems();
        }
    }

    isValid(): boolean {
        const isValid = this.assignments.every(assignment => {
            if (!assignment.type || !assignment.title) return false;
            
            if (assignment.type === 'Module') {
                return assignment.selectedItems !== undefined && 
                       (assignment.selectedContentIds?.length ?? 0) > 0;
            }
            
            return (assignment.type === 'Assessment' || assignment.type === 'Exercise') ? 
                   assignment.selectedItems !== undefined : true;
        }) && !!this.selectedParticipantId;
        
        return isValid;
    }

    async saveAssignments() {
        if (!this.isValid()) return;

        this.loading = true;
        this.apiService.addTrainerNewAssignmentsToParticipants({
            participants: this.selectedParticipantId!,
            assignments: this.assignments
        }).then((response: any) => {
            if (response.success) {
                this.snackbar.open('Assignments saved successfully', 'Close', { duration: 3000 });
                this.modalController.dismiss({ success: true });
            } else {
                throw new Error('Failed to save assignments');
            }
        }).catch((error: any) => {
            this.snackbar.open('Error saving assignments', 'Close', { duration: 3000 });
        });
    }

    back() {
        this.modalController.dismiss();
    }

    // Add new method to handle module selection
    onModuleChange(assignmentIndex: number) {
        const assignment = this.assignments[assignmentIndex];
        // Reset content selection when module changes
        assignment.selectedContentIds = [];
    }

    // Add method to handle select all contents
    selectAllContents(assignmentIndex: number, moduleId: number) {
        const assignment = this.assignments[assignmentIndex];
        const contents = this.getModuleContents(moduleId);
        
        if (!assignment.selectedContentIds || 
            assignment.selectedContentIds.length !== contents.length) {
            assignment.selectedContentIds = contents.map(c => c.ContentId);
        } else {
            assignment.selectedContentIds = [];
        }
    }
} 