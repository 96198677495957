import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';

interface Assignment {
    type: string;
    selectedItems?: number;
    dueDate: Date;
    title: string;
    description: string;
    recurring: {
        enabled: boolean;
        frequency: 'daily' | 'weekly' | 'monthly';
        repeatEvery: number;
        endDate?: Date;
    };
}

@Component({
    selector: 'sb-add-grading-assignment-modal',
    templateUrl: './add-grading-assignment-modal.component.html',
    styleUrls: ['./add-grading-assignment-modal.component.scss']
})
export class AddGradingAssignmentsModalComponent implements OnInit {
    @Input() participantId?: number;
    @Input() participant?: any;

    loading = false;
    participants: any[] = [];
    selectedParticipantId?: any[] = [];

    gradingAssessments: any[] = [];
    submissions: any = {};

    constructor(
        private modalController: ModalController,
        private apiService: ApiService,
        private snackbar: MatSnackBar,
        private router: Router
    ) { }

    ngOnInit() {
        if (this.participant) {
            this.selectedParticipantId = [this.participant.UserId];
        } else {
            this.loadParticipants();
        }
        this.loadGradingAssignments();
    }

    async loadParticipants() {
        this.loading = true;
        this.apiService.getTrainerParticipantsList().then((response: any) => {
            this.participants = response.data;
            this.loading = false;
        }).catch((error: any) => {
            this.snackbar.open('Error loading participants', 'Close', { duration: 3000 });
            this.loading = false;
        });
    }

    async loadGradingAssignments() {
        this.apiService.getTrainerAssessments(this.participant?.UserId).then((response: any) => {
            this.gradingAssessments = response.data.grading;
            response.data.gradingSubmissions.forEach((item: any) => {
                this.submissions[item.AssessmentId] = item;
            });
        }).catch((error: any) => {
            this.snackbar.open('Error loading grading assignments', 'Close', { duration: 3000 });
        });
    }
    onParticipantChange($event: any) {
        console.log($event);
        console.log(this.selectedParticipantId.length, this.participants.length);
    }

    selectAllParticipants() {
        if (this.selectedParticipantId.length === this.participants.length) {
            this.selectedParticipantId = [];
        } else {
            this.selectedParticipantId = [0, ...this.participants.map(p => p.Id)];
        }
    }

    handleGrading(assessment: any) {
        if(this.submissions[assessment.AssessmentId]) {
            var submissionId = this.submissions[assessment.AssessmentId].SubmissionId;
            this.router.navigateByUrl(`/assessment/grading/${assessment.AssessmentId}/${submissionId}`);
            this.back();
        } else {

            if(this.selectedParticipantId.length === 0) {
                this.selectedParticipantId = [...this.participants.map(p => p.Id)];    
            }
            
            this.apiService.createTrainerAssessmentSubmission(assessment.AssessmentId, this.selectedParticipantId).then((response: any) => {
                this.router.navigateByUrl(`/assessment/grading/${assessment.AssessmentId}/${response.data.SubmissionId}`);
                this.back();
            }).catch((error: any) => {
                this.snackbar.open('Error creating submission', 'Close', { duration: 3000 });
            });
        }
    }

    back() {
        this.modalController.dismiss();
    }
} 