<div class="dialog-container">
  <h2 mat-dialog-title>Log Exercise</h2>
  
  <form [formGroup]="exerciseForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Exercise Type</mat-label>
        <mat-select formControlName="exerciseType">
          <mat-option *ngFor="let exercise of predefinedExercises" 
                     [value]="exercise">
            {{exercise}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Location</mat-label>
        <mat-select formControlName="location" 
                   (selectionChange)="onLocationChange($event.value)">
          <mat-option *ngFor="let location of predefinedLocations" 
                     [value]="location">
            {{location}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="customLocation" 
                      appearance="outline" 
                      class="full-width">
        <mat-label>Custom Location</mat-label>
        <input matInput formControlName="customLocationInput">
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Duration (minutes)</mat-label>
        <mat-select formControlName="duration" 
                   (selectionChange)="onDurationChange($event.value)">
          <mat-option [value]="0">Custom</mat-option>
          <mat-option *ngFor="let duration of predefinedDurations" 
                     [value]="duration">
            {{duration}} minutes
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="customDuration" 
                      appearance="outline" 
                      class="full-width">
        <mat-label>Custom Duration (minutes)</mat-label>
        <input matInput type="number" formControlName="customDurationInput">
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" 
              type="submit" 
              [disabled]="exerciseForm.invalid">
        Log Exercise
      </button>
    </mat-dialog-actions>
  </form>
</div>