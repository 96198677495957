import { ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
// import { IAPProduct, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { AlertController, IonModal, ModalController, Platform } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SubscribePopupComponent } from '../subscribe-popup/subscribe-popup.component';

@Component({
  selector: 'app-subscribe-bar',
  templateUrl: './subscribe-bar.component.html',
  styleUrls: ['./subscribe-bar.component.scss'],
})
export class SubscribeBarComponent implements OnInit {

  presentingElement = undefined;
  @ViewChild(IonModal) modal: IonModal;

  @Input() isMember: boolean = false;
  @Input() membership: any;

  subscriptionState: any;

  organisation?: any;
  public subscription_name = 'adios_core_subscription';

  store_Programs: any = [];
  store_prices: any = {};

  subscribing: boolean = false;
  isMobile?: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private common: CommonService,
    // private store: InAppPurchase2,
    private apiService: ApiService,
    private zone: NgZone,
    private userService: UserService,
    private alertController: AlertController,
    private ref: ChangeDetectorRef,
    private plt: Platform,
    private router: Router
    ) {
    
    this.plt.ready().then(() => {
      this.common.canUseNative().then((isNative: boolean) => {
        if (isNative) {
          // Only for debugging!
          this.isMobile = true;
          if (this.common.isIos()) {
            // this.subscription_name = this.VENUS_CORE_UPGRADE_IOS;
          }

          // this.store.verbosity = this.store.DEBUG;

          // // this.registerProducts();
          // this.setupListeners();

          // // Get the real product information
          // this.store.ready(() => {
          //   this.store_Programs = this.store.products;
          //   this.store_Programs.forEach((p: IAPProduct) => {
          //     this.store_prices[p.id] = p;
          //   });
          //   this.ref.detectChanges();
          // });
        }
      });
    });

    this.subscriptionState = this.userService.getSubscriptionState();

    this.userService.subscriptionState.subscribe((value) => {
      console.log(value);
      this.subscriptionState = value;
    });

    // this.subscriptionState = this.userService.subscriptionState;
  }

  ngOnInit() {
    this.presentingElement = document.querySelector('.ion-page');
  }

  subscribeToMembership() {
    this.subscribing = true;
    this.apiService
      .subscribeMembershipV2(
        this.membership.Offers[0].MembershipOfferId,
        environment.category
      )
      .then((res: any) => {
        if (res.success) {
          this.router.navigateByUrl(
            '/payment/finish/' + res.data.orderRef + '?t=' + res.data.token
          );
        }
        this.subscribing = false;
      })
      .catch((e) => {
        if (e.msg) {
          e.msg = 'Something went wrong';
        }
        this.snackbar.open(e.msg);
        this.subscribing = false;
      });
  }

  // registerProducts() {
  //   this.store.register({
  //     id: this.subscription_name,
  //     type: this.store.PAID_SUBSCRIPTION,
  //   });
  //   this.store.refresh();
  // }

  // setupListeners() {
  //   // General query to all products
  //   this.store
  //     .when('product')
  //     .approved((p: any) => {
  //       if (p.id == this.subscription_name) {
  //         p.verify();
  //         this.apiService
  //           .coreOrderAndroidApproved(p.additionalData.orderId, {
  //             PlaySubscriptionState: p.state,
  //             PlayDeveloperPayload: p.transaction.developerPayload,
  //             PlayPurchaseState: p.transaction.purchaseState,
  //             PlayPurchaseToken: p.transaction.purchaseToken,
  //             PlayReceipt: p.transaction.receipt,
  //             PlaySignature: p.transaction.signature,
  //             PlayType: p.transaction.type,
  //             PlayTransactionId: p.transaction.id,
  //           })
  //           .then((res: any) => {
  //             if (res.success) {
  //               p.verify();
  //             }
  //           })
  //           .catch((e) => {
  //             console.log('Error');
  //             this.resetPurchaseLoaders();
  //           });
  //       }
  //     })
  //     .verified((p: any) => {
  //       console.log('Verified : ', p);

  //       this.apiService
  //         .coreOrderAndroidApproved(p.additionalData.orderId, {
  //           PlaySubscriptionState: p.state,
  //           PlayDeveloperPayload: p.transaction.developerPayload,
  //           PlayPurchaseState: p.transaction.purchaseState,
  //           PlayPurchaseToken: p.transaction.purchaseToken,
  //           PlayReceipt: p.transaction.receipt,
  //           PlaySignature: p.transaction.signature,
  //           PlayType: p.transaction.type,
  //           PlayTransactionId: p.transaction.id,
  //         })
  //         .then((res: any) => {
  //           if (res.success) {
  //             p.finish();
  //           }
  //         })
  //         .catch((e) => {
  //           console.log('Error');
  //           this.resetPurchaseLoaders();
  //         });

  //       // var data: any = {
  //       //   BaseAmount: p.priceMicros / 1000000,
  //       //   Total: p.priceMicros / 1000000,
  //       //   Currency: p.currency,
  //       //   PaymentTxMsg: JSON.stringify(p.transaction),
  //       //   Trial: false,
  //       // };

  //       // if (p.id === this.VENUS_CORE_TRIAL) {
  //       //   data.Trial = true;
  //       //   this.joiningtrial = true;
  //       // }

  //       // if (p.id == this.subscription_name) {
  //       //   this.apiService
  //       //     .processJoiningBatch(data, this.joiningBatchCode, this.joiningtrial)
  //       //     .then((res: any) => {
  //       //       this.loading = false;
  //       //       if (res.success) {
  //       //         p.finish();
  //       //       }
  //       //     })
  //       //     .catch((error) => {
  //       //       if (!error.msg) {
  //       //         error.msg = 'Something went wrong';
  //       //       }
  //       //       this.snackbar.open(error.msg);
  //       //       this.loading = false;
  //       //     });
  //       // }
  //     })
  //     .finished((p: any) => {
  //       this.zone.run(() => {
  //         this.apiService
  //           .coreOrderAndroidAcknowledged(p.additionalData.orderId, {
  //             PlaySubscriptionState: p.state,
  //             PlayDeveloperPayload: p.transaction.developerPayload,
  //             PlayPurchaseState: p.transaction.purchaseState,
  //             PlayPurchaseToken: p.transaction.purchaseToken,
  //             PlayReceipt: p.transaction.receipt,
  //             PlaySignature: p.transaction.signature,
  //             PlayType: p.transaction.type,
  //             PlayTransactionId: p.transaction.id,
  //           })
  //           .then((res: any) => {
  //             const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //               width: '450px',
  //               data: {
  //                 title: 'Thank you for starting your journey with Adios',
  //                 message:
  //                   'Thank you for subscribing to Adios. We have emailed you the subscription details. You will receive the details of the program in a separate email.\n\n Please go through the weekly sessions and attend the live Zoom sessions to get your questions answered and keep yourself motivated.',
  //                 positive: 'GET STARTED',
  //                 showNegative: false,
  //               },
  //             });

  //             dialogRef.afterClosed().subscribe((result) => {
  //               //console.log(result);
  //               if (result) {
  //                 // this.viewProgramSpace(event.programParticipant);
  //               }
  //             });

  //             this.resetPurchaseLoaders();
  //             this.ngOnInit();

  //           })
  //           .catch((e) => {
  //             console.log('Error');
  //             this.resetPurchaseLoaders();
  //           });
  //       });
  //     })
  //     .error((p: any) => {
  //       this.zone.run(() => {
  //         this.resetPurchaseLoaders();
  //       });
  //     })
  //     .invalid((p: any) => {
  //       this.zone.run(() => {
  //         this.resetPurchaseLoaders();
  //       });
  //     })
  //     .cancelled((p: any) => {
  //       this.zone.run(() => {
  //         this.resetPurchaseLoaders();
  //       });
  //     });

  //   // Specific query for one ID
  //   this.store.when(this.subscription_name).owned((p: IAPProduct) => {
  //     console.log('Venus Core ', p);
  //   });
  //   this.store.error((e) => {
  //     console.log('ERROR ' + e.code + ': ' + e.message);
  //     this.zone.run(() => {
  //       this.resetPurchaseLoaders();
  //     });
  //   });
  // }

  // resetPurchaseLoaders() {
  //   // this.joiningBatch = false;
  //   // this.joiningtrial = false;
  //   // this.joiningBatchId = undefined;
  //   // this.joiningBatchCode = undefined;
  //   // this.upgradingTrial = false;
  //   // this.ref.detectChanges();
  //   this.userService.clearUpgradingParticipant();
  //   this.userService.clearJoiningCohort();
  //   this.subscribing = false;
  // }

  // purchase(product: IAPProduct) {
  //   this.subscribing = true;

  //   this.apiService
  //     .createAndroidInAppOrder({
  //       BaseAmount: product.priceMicros / 1000000,
  //       Total: product.priceMicros / 1000000,
  //       FinalAmount: product.priceMicros / 1000000,
  //       Amount: product.priceMicros / 1000000,
  //       TotalWithTax: product.priceMicros / 1000000,
  //       Currency: product.currency,
  //       ProgramCategoryId: this.membership.ProgramCategoryId,
  //       MembershipOfferId: this.membership.Offers[0].MembershipOfferId,
  //       PlaySubscriptionId: this.subscription_name,
  //       PlaySubscriptionState: 'initiated',
  //       Trial: false,
  //     })
  //     .then((res: any) => {
  //       if (res.success) {
  //         this.store
  //           .order(product, {
  //             orderId: res.data.OrderId,
  //             orderReference: res.data.OrderReference,
  //             category: environment.category,
  //             userId: this.userService.user.UserId,
  //             email: this.userService.user.Email,
  //             applicationUsername:
  //               this.userService.user.FirstName +
  //               ' ' +
  //               this.userService.user.LastName,
  //           })
  //           .then(
  //             (p) => {
  //               // Purchase in progress!=
  //               console.log('Purchase in progress! ', p);
  //             },
  //             (e) => {
  //               this.subscribing = false;
  //               this.presentAlert('Failed', `Failed to purchase: ${e}`);
  //             }
  //           );
  //       } else {
  //         this.subscribing = false;
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('Error');
  //       if (!e.msg) {
  //         e.msg = 'Something went wrong';
  //       }
  //       this.snackbar.open(e.msg);
  //     });
  // }

  // To comply with AppStore rules
  // restore() {
  //   this.store.refresh();
  // }

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }



  startingTrial: boolean = false;
  startTrial() {
    if(this.startingTrial) {
      return false;
    }
    this.startingTrial = true;
    this.apiService
      .startFreeTrial()
      .then((res: any) => {
        this.startingTrial = false;
        this.router.navigateByUrl('/dashboard/programs?st=1');
      })
      .catch((error) => {
        console.log(error);
        if (!error.msg) {
          error.msg = 'Something went wrong';
        }
        this.snackbar.open(error.msg);
        this.startingTrial = false;
      });
  }


  async openSubscribeModal() {
    const modal = await this.modalCtrl.create({
      component: SubscribePopupComponent,
      // presentingElement: this.presentingElement,
      // breakpoints: [0, 0.3, 0.5, 0.8, 1],
      // initialBreakpoint: 1
    });
    modal.onDidDismiss().then((value: any) => {
      if(value.data) {
      }
    }).catch(e => {
      console.log(e);
    });
    await modal.present();
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss("", 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    console.log(ev);
    if (ev.detail.role === 'confirm') {

      // this.message = `Hello, ${ev.detail.data}!`;
    }
  }

  recommendAdios() {
    this.router.navigateByUrl('/recommend');
  }

  useCode() {
    this.router.navigateByUrl('/use-code');
  }
  
}
