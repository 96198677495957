import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AssessmentGuard } from './shared/guard/assessment.guard';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'welcome',
    loadChildren: () => import('./intro-screen/intro-screen.module').then( m => m.IntroScreenPageModule)
  },
  {
    path: 'tools',
    loadChildren: () => import('./tools/tools.module').then( m => m.ToolsModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then( m => m.OnboardingPageModule),
  },
  {
    path: 'adioscope',
    loadChildren: () => import('./adioscope/adioscope.module').then( m => m.AdioscopeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'content',
    loadChildren: () => import('./program-content/program-content.module').then(m => m.ProgramContentModule),
    canActivate: [AuthGuard],
    // runGuardsAndResolvers: 'always',
  },
  {
    path: 'contemplations',
    loadChildren: () => import('./contemplation-content/contemplation-content.module').then(m => m.ContemplationContentModule),
    canActivate: [AuthGuard],
    // runGuardsAndResolvers: 'always',
  },
  {
    path: 'profile',
    loadChildren: () => import('./profiles/profiles.module').then( m => m.ProfilesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'favorites',
    loadChildren: () => import('./favorites/favorites.module').then( m => m.FavoritesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'assessment',
    loadChildren: () => import('./assessment/assessment.module').then(m => m.AssessmentModule),
    canActivate: [AssessmentGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'code',
    loadChildren: () => import('./code/code.module').then( m => m.CodePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recommend',
    loadChildren: () => import('./recommend/recommend.module').then( m => m.RecommendPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'validate',
    loadChildren: () => import('./validate/validate.module').then( m => m.ValidatePageModule)
  },
  {
    path: 'pending-invites',
    loadChildren: () => import('./pending-invites/pending-invites.module').then( m => m.PendingInvitesPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./delete-account/delete-account.module').then( m => m.DeleteAccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then( m => m.UnsubscribeModule)
  },
  {
    path: 'subscribe',
    canActivate: [AuthGuard],
    loadChildren: () => import('./subscribe-membership/subscribe-membership.module').then( m => m.SubscribeMembershipPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
