<mat-card class="assessment-card">
    <mat-card-header>
        <mat-card-title>{{ assessment.Title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Total Questions: {{ assessment.TotalQuestions }}</p>
        <p *ngIf="submissions">Last Updated: {{ submissions.UpdatedOn | date:'MM/dd/yyyy hh:mm a' }}</p>
        <span class="status" *ngIf="assessment.Status == 'Completed'">Completed</span>
        <span class="status" *ngIf="assessment.Status == 'Draft'">Draft</span>
        <span class="status" *ngIf="assessment.Status == 'Not Attempted'">Not Started</span>
    </mat-card-content>
    <mat-card-actions>
        <app-adios-btn *ngIf="isGrading" type="button" (click)="handleGrading()" [addmargin]="false" [addmarginleft]="false"
            highlight="lillac">
            {{ submissions ? 'Resume Grading' : 'Start Grading' }}
        </app-adios-btn>
        <app-adios-btn *ngIf="!isGrading" type="button" (click)="handleGrading()" [addmargin]="false" [addmarginleft]="false"
            highlight="lillac">
            {{ assessment.Status == 'Draft' ? 'Resume Assessment' : 'Start Assessment' }}
        </app-adios-btn>
    </mat-card-actions>
</mat-card> 