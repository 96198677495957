<div class="row w-100 h-100 m-0 content-section">
    <div class="col-md-12 p-0 col-12 messagebox">
        <div class="box-container content-center">
            <div class="spinner-border" role="status" *ngIf="loading">
                <span class="sr-only"></span>
            </div>
            <div class="list-box" *ngIf="!loading && messages.length == 0">
                <div class="row m-0 w-100">
                    <div class="col-md-12">
                        <div class="box content-center">
                            <p>No messages yet</p>
                        </div>
                    </div>
                </div>
            </div>
            <div #messagelist class="message-list scrollbox" *ngIf="!loading && messages.length > 0" infiniteScroll
                [infiniteScrollUpDistance]="2" [infiniteScrollDistance]="2" [scrollWindow]="false"
                [alwaysCallback]="true" (scrolledUp)="onUp()" (scrolled)="onScrollDown()">

                <div class="spinner-border inline-center" role="status" *ngIf="loadingPreviousMessages">
                    <span class="sr-only"></span>
                </div>
                <ng-container *ngFor="let message of messages">
                    <div class="message-card" [id]="message.MessageId"
                        [class.right-message]="message.CreatedBy == userId">
                        <p *ngIf="message.CreatedBy != userId" class="meta"><small>{{ message.User.FirstName }} {{
                                message.User.LastName }}</small></p>
                        <p *ngIf="message.Text">{{ message.Text }}</p>
                        <ng-container *ngIf="message.Type == 'SUBMISSION'">
                            <video controls [src]="message.MediaUrl + sas" class="mx-w-450"></video>
                            <small class="mt-2" *ngIf="message.RequiresReview && message.IsReviewed">Reviewed</small>
                            <button class="btn btn-default btn-sm mt-2" [disabled]="message.IsReviewing" *ngIf="message.RequiresReview && !message.IsReviewed && isTrainer" (click)="markSubmissionAsReviewed(message)">
                                <span *ngIf="message.IsReviewing">Reviewing...</span>
                                <span *ngIf="!message.IsReviewing">Mark Reviewed</span>
                            </button>
                        </ng-container>
                        <p class="timestamp">
                            <small *ngIf="message.CreatedOn.indexOf('Z') == -1">{{ message.CreatedOn + 'Z' | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                            <small *ngIf="message.CreatedOn.indexOf('Z') != -1">{{ message.CreatedOn | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                        </p>
                    </div>
                </ng-container>
                <p #unreadlist *ngIf="unread.length > 0" class="small-text"><span>Unread</span></p>
                <ng-container *ngFor="let message of unread">
                    <div class="message-card" [id]="message.MessageId" [class.right-message]="message.CreatedBy == userId">
                        <p *ngIf="message.CreatedBy != userId" class="meta"><small>{{ message.User.FirstName }} {{
                                message.User.LastName }}</small></p>
                        <p *ngIf="message.Text">{{ message.Text }}</p>
                        <p class="timestamp">
                            <small *ngIf="message.CreatedOn.indexOf('Z') == -1">{{ message.CreatedOn + 'Z' | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                            <small *ngIf="message.CreatedOn.indexOf('Z') != -1">{{ message.CreatedOn | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                        </p>
                    </div>
                </ng-container>
                <div #listbottom id="bottom"></div>
            </div>
        </div>
        <div *ngIf="hasNewMessages" class="new-message-band">
            <span>New Messages</span>
        </div>
        <div class="message-input-box w-100">
            <mat-form-field appearance="outline" class="mr-2" style="flex: 1;">
                <textarea [disabled]="sendingMessage" matInput class="message-input contemplation-space"
                    placeholder="Start typing..." [(ngModel)]="newMessageText.Text"></textarea>
            </mat-form-field>
            <div class="action-box">
                <div class="spinner-border p-2" *ngIf="sendingMessage" role="status">
                    <span class="sr-only"></span>
                </div>
                <ng-container *ngIf="!sendingMessage">
                    <button mat-icon-button aria-label="Send" (click)="sendMessage()">
                        <mat-icon>send</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Attach">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>