<div>
  <ng-container *ngIf="uploadedUrl">
    <p>You have already submitted this video</p>
    
    <div class="record-container">
      <video id="playback" [src]="uploadedUrl + _sas" controls style="width: 100%; height: auto; margin:0px;"></video>
    </div>
    <div class="action-group mb-5">
      <ion-button class="text-white" (click)="openRecordingPopup()" *ngIf="!uploading">Record Again</ion-button>
      <ion-button class="text-white ml-2" (click)="submitForReview()" *ngIf="!uploading && uploadedUrl && !reviewed">Submit for Reviewing</ion-button>
      <ion-button class="text-white ml-2" disabled *ngIf="!uploading && uploadedUrl && reviewed && !reviewedBy">In Review</ion-button>
      <ion-button class="text-white ml-2" disabled *ngIf="!uploading && uploadedUrl && reviewedBy">Reviewed</ion-button>
    </div>
  </ng-container>

  <ng-container *ngIf="!uploadedUrl">
    <div class="action-group mb-5">
      <ion-button class="text-white" (click)="openRecordingPopup()">Record Video</ion-button>
    </div>
  </ng-container>
</div>