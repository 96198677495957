<div class="align-items-center row" *ngIf="loadingContent">
    <div class="col-md-12 text-center p-5">
        <div class="spinner-border"></div>
    </div>
</div>


<div class="col-md-12 w-100 content pt-1" *ngIf="!loadingContent">
    <div class="beige-space w-100 login-form text-left">
        <ng-container *ngFor="let question of questions; let j = index">
            <p class="pb-4 text-start" *ngIf="question.Text" style="border-bottom: 1px solid #eee;" [innerText]="question.Text"></p>
            <ng-container *ngIf="question.Type != 'video'">
                <ng-container *ngIf="question.Title">
                    <mat-label class="mt-4 mb-4 dflex place-start" style="opacity: 0.7;" [innerText]="question.Title"></mat-label>
                    <mat-form-field class="w-100 mb-4" appearance="outline">
                        <textarea matInput class="contemplation-space" style="resize: none; min-height: 150px;" rows="5"
                            name="question_{{ j }}" [disabled]="updatingAnswer"
                            placeholder="Type here..."
                            (blur)="updateAnswer($event, question.ContentQuestionId, answers[question.ContentQuestionId])"
                            (ngModelChange)="answerChanged($event)"
                            [(ngModel)]="answers[question.ContentQuestionId].Explanation"></textarea>
                        <mat-hint *ngIf="answers[question.ContentQuestionId].Explanation != originalAnswers[question.ContentQuestionId].Explanation
                        && !updatingAnswer">
                            Not yet saved. Please click outside the box to save.</mat-hint>
                        <mat-hint *ngIf="answers[question.ContentQuestionId].Explanation != originalAnswers[question.ContentQuestionId].Explanation
                        && updatingAnswer">Saving...</mat-hint>
                    </mat-form-field>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="question.Type == 'video'">
                <ng-container *ngIf="question.Title">
                    <mat-label class="mt-4 mb-4 dflex place-start" style="opacity: 0.7;" [innerText]="question.Title"></mat-label>
                    <app-video-recorder [reviewedBy]="answers[question.ContentQuestionId] && answers[question.ContentQuestionId].ReviewedBy" [reviewed]="answers[question.ContentQuestionId] && answers[question.ContentQuestionId].Reviewed" [uploadedUrl]="answers[question.ContentQuestionId] ? answers[question.ContentQuestionId].Explanation : null" (videoUploaded)="videoUploaded($event, question)" (onSubmitForReview)="onSubmitForReview($event, question)"></app-video-recorder>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
</div>