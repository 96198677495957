import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-assessment-card',
  templateUrl: './assessment-card.component.html',
  styleUrls: ['./assessment-card.component.scss']
})
export class AssessmentCardComponent {
  @Input() assessment: any;
  @Input() submissions: any;

  @Input() isGrading: boolean = false;

  @Output() onGrading = new EventEmitter<any>();

  ngOnInit() {
    console.log(this.assessment);
  }

  handleGrading() {
    this.onGrading.emit(this.assessment);
  }
} 