import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './shared/services/api.service';
import { CommonService } from './shared/services/common.service';
import { DataService } from './shared/services/data/data.service';
import { UserService } from './shared/services/user.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './shared/services/data/storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/services/token-interceptor.service';
import { AuthGuard } from './shared/guard/auth.guard';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { WebHttpService } from './shared/services/http/web-http.service';
import { NativeHttpService } from './shared/services/http/native-http.service';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

// import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

import { LocalNotifications } from '@capacitor/local-notifications';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { Drivers } from '@ionic/storage';
import { HowlAudioService } from './shared/services/howl-audio.service';
import { CurriculumService } from './shared/services/curriculum.service';
import { DatePipe } from '@angular/common';
import { AssessmentGuard } from './shared/guard/assessment.guard';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

import {  AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';

import { environment } from 'src/environments/environment';
import { AssistantService } from './shared/services/assistant.service';
import { MetronomeService } from './shared/services/metronome.service';
import { AudioService } from './shared/services/audio.service';
import { CookieService } from 'ngx-cookie-service';
import Swiper from 'swiper';
import { SessionSchedulerService } from './shared/services/session-scheduler.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatNativeDateModule,
    MatSnackBarModule,
    IonicStorageModule.forRoot({
      name: "adios_core",
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    // IonicStorageModule.forRoot({
    //   name: 'adios_core',
    //   driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] // , Drivers.LocalStorage
    // }),
    IonicModule.forRoot(),
    AppRoutingModule,
    NoopAnimationsModule,
    MatIconModule,
    NgbModule,
    SharedModule,
    MatTooltipModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
  ],
  providers: [
    ApiService, UserService, CommonService, CurriculumService, HowlAudioService, AudioService,
    DatePipe, 
    AngularFireDatabase,
    DataService,
    StorageService,

    WebHttpService,
    SessionSchedulerService,
    NativeHttpService,

    AuthGuard,
    AssessmentGuard,

    MetronomeService,

    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },

    HTTP,
    CookieService,
    AssistantService,

    Platform,
    NativeStorage,
    Deeplinks,
    // InAppPurchase2,
    // MusicControls,
    SignInWithApple
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
