import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Event, NavigationStart, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { ContemplationOptions } from '../contemplation-flow/contemplation-flow.component';
import { MetronomeService } from '../../services/metronome.service';
import { AudioService } from '../../services/audio.service';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls';
declare var jwplayer: any;

import { CapacitorMusicControls } from 'capacitor-music-controls-plugin';

@Component({
  selector: 'app-currently-playing',
  templateUrl: './currently-playing.component.html',
  styleUrls: ['./currently-playing.component.scss'],
})
export class CurrentlyPlayingComponent implements OnInit {

  @Output('markCompleted') markCompleted: EventEmitter<any> = new EventEmitter();
  @Output('toggleFavorite') triggerToggleFavorite: EventEmitter<any> = new EventEmitter();
  @Input('isFavorite') isFavorite?: boolean = false;
  // @Input('options') options?: any;
  @Input('contents') contents?: any = [];
  @Input('progress') progress?: any = {};

  previousContentOptions?: any | ContemplationOptions;
  nextContentOptions?: any | ContemplationOptions;

  loadingContent: boolean = true;
  completing: boolean = false;
  loading: boolean = false;

  currentContemplationIndex: any;

  previousContentTitle: string = "Previous";
  nextContentTitle: string = "Next";

  hasPrevious: boolean = false;
  hasNext: boolean = false;

  state: any;
  metronomeState: any = 'stopped';

  currentTrack: any;

  constructor(
    // public audioService: HowlAudioService,
    public audioService: AudioService,
    public modal: ModalController,
    public apiService: ApiService,
    public snackbar: MatSnackBar,
    public router: Router,
    public userService: UserService,
    public common: CommonService,
    // public musicControls: MusicControls,
    public metronomeService: MetronomeService
  ) {
    this.stop();
  }
 
  ngOnInit(): void {

    this.audioService.getProgress().subscribe(progress => {
      this.progress = progress;
    });
    
    this.audioService.getCurrentTrack().subscribe(track => {
      if (this.metronomeState != 'stopped') {
        this.stopMetronome();
      }
      if (track && track.type) {
        console.log("Setting up notification for ")
        this.setNotificationControl(track)
          .then((r) => {
            // this.currentTrack = track;
            // console.log("this.current Track is: ", this.currentTrack.currentPlaylistContentTitle);
          });

        this.currentTrack = track;
        // console.log("this.current Track is: ", this.currentTrack.currentPlaylistContentTitle);
        // this.currentTrack = track;
      } else {
        this.currentTrack = undefined;
      }
    });
    
    this.audioService.getState().subscribe(t => {
      this.state = t;
      if(this.state.remainingTime < 15) {
        // console.log("this.state: ", this.state);
        this.markContentAsComplete(this.currentTrack);
      }
    });

    this.audioService.getEvents().subscribe(event => {
      // console.log(event);
      if(event.type == "ended"){
        // console.log("End event called", event);
          this.nextContent();
      }
    });

    this.metronomeService.getState().subscribe(t => {
      console.log("Metronome State is :" , t);
      if(t == 'playing') {
        this.pause();
        this.currentTrack = {};
      }

      if(t === "stopped") {
        this.metronomeState = t;
        this.common.canUseNative().then((isNative: boolean) => {
          if (isNative) {
            CapacitorMusicControls.destroy();
          }
        });
        // this.stopMetronome();
      } else {

        if(t == 'playing') {
          this.pause();
          this.currentTrack = {};
        } 

        this.setNotificationControl({
          currentPlaylistTitle: "Walking Metronome",
          currentPlaylistId: "1",
          currentPlaylistContentTitle: "Walking",
          currentContentId: "1",
          currentIndex: 0,
          contents: [],
          type: "Audio",
          playlist: {},
          hasPrevious: false,
          hasNext: false, 
        }).then((r) => {
        });

        this.metronomeState = t;

        this.common.canUseNative().then((isNative: boolean) => {
          if (isNative) {
            if(t == 'playing') {
              CapacitorMusicControls.updateIsPlaying({
                isPlaying : t == 'playing' ? true : false
              });
            }
          }
        });
      }
      
      // setTimeout(() => {
      //   this.setNotificationControl();
      // }, 300);
      
      // this.metronomeState = t;
      // if(this.metronomeState == 'playing'){
      //   // if(this.state) this.currentTrack = {};
      //   // this.pause();
      //   this.common.canUseNative().then((isNative: boolean) => {
      //     if (isNative) {
      //       CapacitorMusicControls.destroy();
      //     }
      //   });
      //   setTimeout(() => {
      //     this.setNotificationControl();
      //   }, 300);
      // }
    });

    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {

        let handleControlsEvent = (action) => {
          const message = JSON.parse(action).message;
          console.log("Music Control message is: ", message);
          switch (message) {
            case 'music-controls-next':
              // alert("Next called");
              // Do something
              this.nextContent();
              break;
            case 'music-controls-previous':
              // alert("Previous called");
              // Do something
              this.previousContent();
              break;
            case 'music-controls-pause':
              // alert("Pause Called");
              (this.metronomeState != 'stopped') ? this.pauseMetronome() : this.pause();
              break;
            case 'music-controls-play':
              // alert("Play Called");
              (this.metronomeState !== 'stopped') ? this.resumeMetronome() : this.resume();
              break;
            case 'music-controls-destroy':
              this.stop();
              // this.stopMetronome();
              break;

            // External controls (iOS only)
            case 'music-controls-toggle-play-pause':
              // Do something
              if(this.metronomeState !== "stopped") {
                if (this.state?.paused || this.state?.canplay) {
                  this.resume();
                } else {
                  this.pause();
                }
              } else {
                if(this.metronomeState === 'playing') {
                  this.pauseMetronome();
                } else {
                  this.resumeMetronome();
                }
              }
              // if (this.state?.paused || this.state?.canplay) {
              //   this.resume();
              // } else {
              //   this.pause();
              // }
              break;
            case 'music-controls-seek-to':
              if (this.metronomeState != 'stopped') {
                const seekToInSeconds = JSON.parse(action).position;
                CapacitorMusicControls.updateElapsed({
                  elapsed: seekToInSeconds,
                  isPlaying: true
                });
              }
              break;
            case 'music-controls-skip-forward':
              // alert("Music Control Skip Forward");
              break;
            case 'music-controls-skip-backward':
              // alert("Music Control Skip Backward");
              break;

            // Headset events (Android only)
            // All media button events are listed below
            case 'music-controls-media-button':
              // Do something
              if (this.metronomeState != 'stopped') {
                if (this.metronomeState == "playing") {
                  this.resumeMetronome();
                } else {
                  this.pauseMetronome();
                }
              } else {
                if (this.state?.paused || this.state?.canplay) {
                  this.resume();
                } else {
                  this.pause();
                }
              }
              break;
            case 'music-controls-headset-unplugged':
              if (this.metronomeState != 'stopped') {
                this.pauseMetronome();
              } else {
                if(this.state?.playing) {
                  this.pause(); 
                }
              }
              break;
            case 'music-controls-headset-plugged':
              // Do something
              break;
            default:
              break;
          }
        };

        // IOS
        CapacitorMusicControls.addListener('controlsNotification', (info: any) => {
          console.log('controlsNotification was fired');
          console.log(info);
          handleControlsEvent(info);
        });

        // ANDROID (13, see bug above as to why it's necessary)
        document.addEventListener('controlsNotification', (event: any) => {
          console.log('controlsNotification was fired');
          console.log(event);
          const info = JSON.stringify({ message : event.message, position: 0 });
          handleControlsEvent(info);
        });
      }
    });

    // CapacitorMusicControls.listen(); // activates the observable above

  }

  changePlayRate() {
    console.log(this.state.playbackSpeed);
    if(this.state.playbackSpeed >= 2) {
      this.state.playbackSpeed = 0.5;
    } else {
      this.state.playbackSpeed += 0.5;
    }
    this.audioService.setPlaybackSpeed(this.state.playbackSpeed);
  }

  setNotificationControl(track) {
    return new Promise((r, j) => {

      this.common.canUseNative().then((isNative: boolean) => {
        if (isNative) {
          console.log("Native Control: ", track.hasPrevious + " " + track.hasNext);
          CapacitorMusicControls.create({
            track: track?.currentPlaylistContentTitle,
            // cover: track.albumArt, 
            artist: track?.currentPlaylistTitle,
            isPlaying: true,
            dismissable: true,
            hasPrev: track.hasPrevious,
            hasNext: track.hasNext,
            hasClose: false,       // show close button, optional, default: false
            hasSkipForward: false,  // show skip forward button, optional, default: false
            hasSkipBackward: false, // show skip backward button, optional, default: false
            hasScrubbing: true, // enable scrubbing from control center and lock screen progress bar, optional,
            skipForwardInterval: 0, 
            skipBackwardInterval: 0,
            playIcon: 'round_play_circle_black_48',
            pauseIcon: 'round_pause_circle_black_48',
            notificationIcon: 'ic_stat_app_icon',
            cover: ''
          }).then((v) => {
            console.log("Control", v);
            r(true);
          }).catch((e) => {
            console.log("Control Error", e);
            j(false);
          });
        } else {
          r(true);
        }
      });
    });
  }

  ngOnDestroy() {
    console.log("current playing destroyed");
    this.audioService.pause();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.destroy();
      }
    });
  }

  setContent() {
    this.loadingContent = true;
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.destroy();
      }
    });
  }

  previousContent() {
    if (this.currentTrack.hasPrevious) {
      this.audioService.skipTo(this.currentTrack.currentIndex - 1);
    }
  }

  nextContent() {
    console.log("Calling next content");
    if (this.currentTrack.hasNext) {
      this.audioService.skipTo(this.currentTrack.currentIndex + 1);
    }
  }

  //Mark the content as completed or watched.
  markContentAsComplete(content: any) {
    if (this.completing) {
      return false;
    }
    if(!this.progress[content.currentContentId]) {
      this.completing = true;
      
      if(this.audioService.type != 'assignment') {
        this.apiService.libraryContentCompleted(content.currentContentId).then((res: any) => {
          if (res.success) {
            this.completing = false;
            this.audioService.markCompleted(content.currentContentId, res.data.playlistContentMeta)
          } else {
            this.completing = false;
            this.snackbar.open("Unable to update your progress");
          }
        }).catch(e => {
          this.completing = false;
        });
      }
    }
  }

  //Get the video file url
  getVideoFile(content: any) {
    return environment.storagedomain + content.Url + environment.sas;
    // return this.apiService.domain + this.apiService.apis.streamVideoContent + content.ContentId + '?s=' + btoa(this.userService.getToken());
  }

  //Standard Audio Service Controls
  pause() {
    this.audioService.pause();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        try {
          CapacitorMusicControls.updateIsPlaying({
            isPlaying: false
          });
        } catch {

        }
      }
    });
  }

  resume() {
    this.audioService.resume();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // CapacitorMusicControls.updateIsPlaying({
        //   isPlaying: true
        // });
        if(this.currentTrack){
          CapacitorMusicControls.create({
            track: this.currentTrack.currentPlaylistContentTitle,
            artist: 'Contemplate',
            isPlaying: true,
            dismissable: false,
            hasPrev: false,      // show previous button, optional, default: true
            hasNext: false,      // show next button, optional, default: true
            hasClose: false,       // show close button, optional, default: false
  
            hasSkipForward: false,  // show skip forward button, optional, default: false
            hasSkipBackward: false, // show skip backward button, optional, default: false
            hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional
  
            // Android only, optional
            // text displayed in the status bar when the notification (and the ticker) are updated, optional
            ticker: this.currentTrack.currentPlaylistContentTitle,
            // All icons default to their built-in android equivalents
            playIcon: 'round_play_circle_black_48',
            pauseIcon: 'round_pause_circle_black_48',
            notificationIcon: 'ic_stat_app_icon',
            // prevIcon: 'media_prev',
            // nextIcon: 'media_next',
            // closeIcon: 'media_close',
          });
        }
      }
    });
  }

  stop() {
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.destroy();
      }
    });
  }

  onSliderChangeStart(t: any) {
    // alert(t);
    console.log(t);
    // this.audioService.seek(t.value);
    // this.audioService.resume();
  }

  onSliderChangeEnd(t: any) {
    // alert(t);
    console.log(t);
    this.audioService.seek(t.value);
    // this.audioService.resume();
  }
  onVolumeChange(t: any) {
    this.audioService.setVolume(t.value)
  }

  closeDialog() {
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.destroy();
      }
    });
    this.audioService.resetState();
    this.modal.dismiss();
    // this.close.emit();
  }

  pauseMetronome() {
    this.metronomeService.play();
  }

  resumeMetronome() {
    this.metronomeService.play();
  }

  stopMetronome(){
    this.metronomeService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.destroy();
      }
    });
  }

  toggleFavorite() {
    this.triggerToggleFavorite.emit();
  }
}