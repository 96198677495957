import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CongratulationsModalComponent } from '../congratulations/congratulations.component';
import { ScheduleSessionComponent } from '../schedule-session/schedule-session.component';

@Component({
  selector: 'app-personal-adioscope',
  templateUrl: './personal-adioscope.component.html',
  styleUrls: ['./personal-adioscope.component.scss'],
})
export class PersonalAdioscopeComponent implements OnInit {

  @Output() emitPopupClose: EventEmitter<any> = new EventEmitter();

  username?: string;
  loading: boolean = false;
  pending: any;
  currency: any = 'USD';
  multiplyFactor: any = 1;
  dateOffset: any;
  store_Programs: any = [];
  store_prices: any = {};

  pendingInvites: any = [];

  introProgram: any;
  introProgramParticipant: any;
  introContentProgress: any;

  organisation?: any;

  loadingPersonalSituations: boolean = false;
  loadingClearedSituations: boolean = false;
  personalSituations: {
    situations: any,
    currentPage: number,
    pageSize: number,
    totalPages: number,
    loading: boolean,
    isFiltered: boolean,
    cleared: number,
    total: number
  } = {
      situations: [],
      pageSize: 20,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      isFiltered: false,
      cleared: 0,
      total: 0,
    };

  clearedSituations: {
    situations: any,
    currentPage: number,
    pageSize: number,
    totalPages: number,
    loading: boolean,
    isFiltered: boolean,
    cleared: number,
    total: number
  } = {
      situations: [],
      pageSize: 20,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      isFiltered: false,
      cleared: 0,
      total: 0,
    };

  resolvedSituations: any = {};

  criticalities: any = [1, 2, 4, 5];
  newSituation: any = {
    Importance: 2,
    Title: ""
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private common: CommonService,
    private navCtrl: NavController,
    private modalController: ModalController
  ) {
    this.username = this.userService.getUserName();
    var offset = new Date().getTimezoneOffset();
    if (offset > 0) {
      this.dateOffset = "-" + ('0' + Math.floor((offset / 60))).slice(-2) + "" + ('0' + Math.floor((offset % 60)).toFixed(0)).slice(-2);
    } else {
      this.dateOffset = "+" + ('0' + Math.floor(((offset * -1) / 60)).toFixed(0)).slice(-2) + "" + ('0' + Math.floor(((offset * -1) % 60)).toFixed(0)).slice(-2);
    }
    this.currency = this.apiService.getCurrency();
    if (this.currency == 'INR') {
      this.multiplyFactor = 75;
    }
  }

  ngOnInit() {

    this.getPersonalSituations(1);
    this.getClearedSituations(1);

    this.route.queryParams.subscribe((r) => {
      console.log(r);
      if(r['t'] == 0) {
        this.getPersonalSituations(1);
      }
    });
  }

  setCriticality(criticality) {
    this.newSituation.Importance = criticality;
  }

  refreshed(event?: any) {
    this.getPersonalSituations(1, event);
    this.getClearedSituations(1, event);
  }

  ionViewDidEnter() {
    this.getPersonalSituations(1);
  }


  willAnalyse: boolean = false;
  creatingSituation: boolean = false;
  saveSituation(privacy: number, analyse: boolean) {
    // console.log(privacy);

    this.newSituation.Title = "";
    this.newSituation.Privacy = privacy;
    this.willAnalyse = analyse;

    this.creatingSituation = true;
    this.apiService.addSituationV2(this.newSituation).then((res: any) => {
      this.creatingSituation = false;
      this.snackbar.open("Saved");
      this.resetSituation();
      
      this.personalSituations.situations.unshift(res.data);
      if (this.userService.user.IsBeta) {
        this.openScheduler(res.data, analyse);
      } else {
        if(analyse) {
          this.personalSituations.situations.unshift(res.data);
          this.analyseSituation(res.data);
        } else {
          this.personalSituations.situations.unshift(res.data);
        }
      }
    }).catch(e => {
      console.log(e);
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.creatingSituation = false;
    });
  }

  openScheduler(situation, analyse?: any) {
    const dialogRef = this.dialog.open(ScheduleSessionComponent, {
      width: '80%',
      autoFocus: false,
      maxWidth: '800px',
      panelClass: 'round-confirm-box',
      data: {
        situationId: situation.Id,
        title: situation.Title,
        description: situation.Description,
        importance: situation.Importance,
        resolveBy: situation.ResolveBy,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(analyse) {
          this.analyseSituation(situation);
        }
      } else {
        if(analyse) {
          this.analyseSituation(situation);
        }
      }
    });
  }
  
  resetSituation() {
    this.newSituation = {
      Importance: 2,
    }
  }

  getPersonalSituations(page: number, event?: any) {
    if (page > this.personalSituations.totalPages) {
      return;
    }
    this.personalSituations.loading = true;
    this.personalSituations.currentPage = page;
    if (page == 1) {
      this.personalSituations.situations = [];
      this.loadingPersonalSituations = true;
    }
    this.apiService.getPersonalAdioscope(this.personalSituations).then((res: any) => {
      this.personalSituations.loading = false;
      this.loadingPersonalSituations = false;
      if (res.success) {
        // this.personalSituations.situations = res.data.items;

        this.personalSituations.situations = this.personalSituations.situations.concat(res.data.items);
        this.personalSituations.situations.forEach((situation: any) => {
          if (situation.CreatedOn.indexOf('Z') == -1) {
            situation.CreatedOn += 'Z';
          }
        });
        this.personalSituations.total = res.data.Total;
        this.personalSituations.cleared = res.data.Cleared;
        this.personalSituations.totalPages = res.data.TotalPages;
        if(this.personalSituations.totalPages < 1) {
          this.personalSituations.totalPages = 1;
        }
        // this.openScheduler(this.personalSituations.situations[0]);
      } else {
        this.snackbar.open("Something went wrong");
      }
      if (event) {
        event.target.complete();
      }
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.personalSituations.loading = false;
      if (event) {
        event.target.complete();
      }
    });
  }


  getClearedSituations(page: number, event?: any) {
    if (page > this.clearedSituations.totalPages) {
      return;
    }
    this.clearedSituations.loading = true;
    this.clearedSituations.currentPage = page;
    if (page == 1) {
      this.clearedSituations.situations = [];
      this.loadingClearedSituations = true;
    }
    this.apiService.getClearedAdioscope(this.clearedSituations).then((res: any) => {
      this.clearedSituations.loading = false;
      this.loadingClearedSituations = false;
      if (res.success) {
        this.clearedSituations.situations = this.clearedSituations.situations.concat(res.data.items);
        this.clearedSituations.situations.forEach((situation: any) => {
          if (situation.CreatedOn.indexOf('Z') == -1) {
            situation.CreatedOn += 'Z';
          }
          if (situation.ClearedOn && situation.ClearedOn.indexOf('Z') == -1) {
            situation.ClearedOn += 'Z';
          }
        });
        this.clearedSituations.total = res.data.Total;
        this.clearedSituations.cleared = res.data.Cleared;
        this.clearedSituations.totalPages = res.data.TotalPages;
        if(this.clearedSituations.totalPages < 1) {
          this.clearedSituations.totalPages = 1;
        }
      } else {
        this.snackbar.open("Something went wrong");
      }
      if (event) {
        event.target.complete();
      }
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.clearedSituations.loading = false;
      if (event) {
        event.target.complete();
      }
    });
  }

  createSituation() {
    this.emitPopupClose.emit({
      closeParent: true
    });
    setTimeout(() => {
      this.router.navigateByUrl('/adioscope/create');
    }, 10);
  }

  analyseSituation(situation: any) {
    this.emitPopupClose.emit({
      closeParent: true
    });
    setTimeout(() => {
      this.router.navigateByUrl('/dashboard/adioscope/analyse/' + situation.Id);
    }, 10);
  }

  onSituationResolved(event, situation, i) {
    if (event) {
      this.changeSituationState(situation, { checked: true });
    } else {
      this.changeSituationState(situation, { checked: false });
    }
  }

  showCelebration: boolean = true;
  isStateChanging: any = {};
  changeSituationState(situation: any, event: any) {
    this.isStateChanging[situation.Id] = true;
    this.apiService.changeSituationStatus(situation.Id, event.checked).then((res: any) => {
      this.isStateChanging[situation.Id] = false;
      situation.IsCleared = event.checked;
      this.resolvedSituations[situation.Id] = event.checked;
      if (event.checked) {

        this.showCelebration = true;
        setTimeout(() => {
          this.showCelebration = false;
        }, 1000);

        setTimeout(() => {
          this.refreshed();
          this.openCongratulationsModal(situation.Id);          
        }, 1500);
        this.snackbar.open("We have moved this situation to your Resolved tab. You can always move it back here if you want to reopen it");
      } else {
        this.refreshed();
        this.snackbar.open("We have moved this situation to your Ongoing tab.");
      }
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.isStateChanging[situation.Id] = false;
    });
  }

  shown_tab?: number = 0;

  async openCongratulationsModal(id, st?: any) {

    const modal = await this.modalController.create({
      component: CongratulationsModalComponent,
      componentProps: {
        numSituations: this.clearedSituations.total + 1,
        situationId: id
      }
    });
    modal.onDidDismiss().then((data) => {
      
    });
    
    return await modal.present();
  }

  changedTab(event: any) {
    console.log(event);
    this.showTab(event.detail.value);
  }

  showTab(index: any) {
    this.shown_tab = index;
    const queryParams: any = { t: index };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

}
