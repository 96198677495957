<div class="ion-page">
    <ion-content>
        <div class="fullscreen beige-space">
            <div class="box" style="max-width: 800px;">
                <div class="back" (click)="back()">
                    <mat-icon>close</mat-icon>
                </div>
                <h1 class="title flex">Add Assignments <span *ngIf="participant"> for {{participant.FirstName}}
                        {{participant.LastName}}</span>
                    <div class="ml-auto align-self-end pr-4"></div>
                </h1>

                <div class="box-body overflow-auto">
                    <!-- Participant Selection if not provided -->
                    <div class="participant-selection w-100" *ngIf="!participant">
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                            <mat-select [(ngModel)]="selectedParticipantId"
                                (selectionChange)="onParticipantChange($event)" multiple
                                placeholder="Select Participant">
                                <mat-label>Select Participant</mat-label>
                                <mat-option (click)="selectAllParticipants()" [value]="0">
                                    <span *ngIf="selectedParticipantId?.length == participants.length + 1">Unselect
                                        All</span>
                                    <span *ngIf="selectedParticipantId?.length != participants.length + 1">Select All
                                        ({{participants.length}})</span>
                                </mat-option>
                                <mat-option *ngFor="let p of participants" [value]="p.Id">
                                    {{p.FirstName}} {{p.LastName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Assignment List -->
                    <div class="assignments-list w-100" *ngIf="participant || selectedParticipantId">
                        <div class="assignment-items">
                            <div class="assignment-item" *ngFor="let assignment of assignments; let i = index">
                                <mat-card>
                                    <mat-card-content>
                                        <!-- Title -->
                                        <div class="assignment-title">
                                            <mat-label>Assignment Period (Dates From - To)</mat-label>
                                            <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                <input matInput [(ngModel)]="assignment.title"
                                                    placeholder="Assignment Period (Dates From - To)" required>
                                            </mat-form-field>
                                        </div>

                                        <!-- Description -->
                                        <div class="assignment-description">
                                            <mat-label>Assignment Description</mat-label>
                                            <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                <textarea matInput [(ngModel)]="assignment.description"
                                                    placeholder="Assignment Description" rows="3"></textarea>
                                            </mat-form-field>
                                        </div>

                                        <!-- Type Selection -->
                                        <div class="assignment-type">
                                            <mat-label>Assignment Type</mat-label>
                                            <mat-form-field appearance="outline" floatLabel="always">
                                                <mat-select [(ngModel)]="assignment.type"
                                                    (selectionChange)="onTypeChange(i)"
                                                    placeholder="Select Assignment Type">
                                                    <mat-option *ngFor="let type of assignmentTypes" [value]="type">
                                                        {{type}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!-- Items Selection based on type -->
                                        <div class="assignment-items" *ngIf="assignment.type">
                                            <ng-container *ngIf="assignment.type == 'Assessment'">
                                                <mat-label>Select Assessment</mat-label>
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <mat-select [(ngModel)]="assignment.selectedItems"
                                                        [placeholder]="'Select Assessment'">
                                                        <mat-option *ngFor="let item of getItemsByType(assignment.type)"
                                                            [value]="item.id">
                                                            {{item.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngIf="assignment.type == 'Module'">
                                                <div class="assignment-due-date">
                                                    <mat-label>Select Module</mat-label>
                                                    <mat-form-field appearance="outline" class="w-100"
                                                        floatLabel="always">
                                                        <mat-select [(ngModel)]="assignment.selectedItems"
                                                            (selectionChange)="onModuleChange(i)"
                                                            [placeholder]="'Select Module'">
                                                            <mat-option *ngFor="let item of getItemsByType(assignment.type)"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <!-- Content Selection -->
                                                <div *ngIf="assignment.selectedItems && getModuleContents(assignment.selectedItems).length > 0">
                                                    <mat-label>Select Content</mat-label>
                                                    <mat-form-field appearance="outline" class="w-100"
                                                        floatLabel="always">
                                                        <mat-select [(ngModel)]="assignment.selectedContentIds"
                                                            [placeholder]="'Select Content'"
                                                            multiple>
                                                            <mat-option (click)="selectAllContents(i, assignment.selectedItems)">
                                                                <span *ngIf="assignment.selectedContentIds?.length === getModuleContents(assignment.selectedItems).length">
                                                                    Unselect All
                                                                </span>
                                                                <span *ngIf="assignment.selectedContentIds?.length !== getModuleContents(assignment.selectedItems).length">
                                                                    Select All ({{getModuleContents(assignment.selectedItems).length}})
                                                                </span>
                                                            </mat-option>
                                                            <mat-option *ngFor="let content of getModuleContents(assignment.selectedItems)"
                                                                [value]="content.ContentId">
                                                                {{content.ContentName}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="assignment.type == 'Exercise'">
                                                <mat-label>Select Exercise</mat-label>
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <mat-select [(ngModel)]="assignment.selectedItems"
                                                        [placeholder]="'Select Exercise'">
                                                        <mat-option *ngFor="let item of getItemsByType(assignment.type)"
                                                            [value]="item.id">
                                                            {{item.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="assignment.type == 'Adioscope'">
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <input matInput [(ngModel)]="assignment.selectedItems" [placeholder]="'Please enter your message for the participant'" />
                                                </mat-form-field>
                                            </ng-container> -->
                                            <ng-container *ngIf="assignment.type == 'Other'">
                                                <mat-label>Message</mat-label>
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <textarea matInput [(ngModel)]="assignment.selectedItems"
                                                        placeholder="Please enter your message for the participant">

                                                    </textarea>
                                                </mat-form-field>
                                            </ng-container>
                                        </div>
                                        <!-- Due Date -->
                                        <div class="assignment-due-date">
                                            <mat-label>Due Date</mat-label>
                                            <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                <input matInput [matDatepicker]="picker"
                                                    [(ngModel)]="assignment.dueDate" placeholder="Due Date" required>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                        <!-- Recurring Settings -->
                                        <!-- <div class="recurring-settings">
                                            <div class="recurring-settings-header">
                                                <mat-checkbox [(ngModel)]="assignment.recurring.enabled">Recurring
                                                    Assignment</mat-checkbox>
                                            </div>

                                            <div class="recurring-options" *ngIf="assignment.recurring.enabled">
                                                <div class="recurring-options-frequency">
                                                    <mat-label>Frequency</mat-label>
                                                    <mat-form-field appearance="outline" floatLabel="always">
                                                        <mat-select [(ngModel)]="assignment.recurring.frequency"
                                                            placeholder="Frequency">
                                                            <mat-option value="daily">Daily</mat-option>
                                                            <mat-option value="weekly">Weekly</mat-option>
                                                            <mat-option value="monthly">Monthly</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div class="recurring-options-repeat">
                                                    <mat-label>Repeat Every</mat-label>
                                                    <mat-form-field appearance="outline" floatLabel="always">
                                                        <input matInput type="number"
                                                            [(ngModel)]="assignment.recurring.repeatEvery"
                                                            placeholder="Repeat Every">
                                                        <span matSuffix>{{assignment.recurring.frequency}}</span>
                                                    </mat-form-field>
                                                </div>

                                                <div class="recurring-options-end-date">
                                                    <mat-label>End Date (Optional)</mat-label>
                                                    <mat-form-field appearance="outline" floatLabel="always">
                                                        <input matInput [matDatepicker]="endPicker"
                                                            [(ngModel)]="assignment.recurring.endDate"
                                                            placeholder="End Date (Optional)">
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="endPicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #endPicker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div> -->

                                    </mat-card-content>
                                    <mat-card-actions class="d-flex justify-content-end">
                                        <button mat-stroked-button class="ml-2" (click)="removeAssignment(i)">
                                            <mat-icon>delete</mat-icon> Remove Assignment
                                        </button>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>

                        <div class="actions mt-3">
                            <button mat-stroked-button (click)="addNewAssignment()">
                                <mat-icon>add</mat-icon> Add Another Assignment
                            </button>

                            <button mat-raised-button color="primary" class="text-white" [disabled]="!isValid()"
                                (click)="saveAssignments()">
                                Send Assignments
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ion-content>
</div>