import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'sb-journaling-questions',
  templateUrl: './journaling-questions.component.html',
  styleUrls: ['./journaling-questions.component.scss']
})
export class JournalingQuestionsComponent implements OnInit {

  @Input('contentId') contentId: any;
  @Input('programParticipantId') programParticipantId: any;
  loadingContent: boolean = true;
  questions: any = [];
  answers: any = {};
  originalAnswers: any = {};

  updatingAnswer: any;
  isAnswerModified: boolean = false;

  constructor(private apiService: ApiService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.getContentQuestions();
  }

  getContentQuestions() {
    this.loadingContent = true;
    this.apiService.getContentQuestions(this.contentId, this.programParticipantId).then((res: any) => {
      if (res.data) {
        this.questions = res.data.questions;
        // this.questions.push({
        //   ContentQuestionId: this.questions[this.questions.length - 1].ContentQuestionId,
        //   Order: this.questions.length,
        //   Title: "Please record yourself for this",
        //   Text: "Please record yourself for this",
        //   Type: "video"
        // })
        this.answers = res.data.answers;
        var x = 0;
        this.questions.forEach((question: any) => {
          if (!this.answers[question.ContentQuestionId.toString()]) {
            this.answers[question.ContentQuestionId.toString()] = {};
          }
          x++;
          if(x == this.questions.length) {
            this.originalAnswers = JSON.parse(JSON.stringify(this.answers));
            this.loadingContent = false;
          }
        });

      } else {
        this.loadingContent = false;
      }
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.loadingContent = false;
    });
  }

  videoUploaded(event: any, question: any) {
    console.log("Uploaded Event : ",event);
    this.answers[question.ContentQuestionId].Explanation = event;
    this.isAnswerModified = true;
    this.updateAnswer(event, question.ContentQuestionId, this.answers[question.ContentQuestionId]);
  }
  submittingAnswer: boolean = false;
  onSubmitForReview(event, question) {
    console.log(event);
    var answer = this.answers[question.ContentQuestionId];

    if (answer && answer.ContentQuestionMetaId) {
      this.apiService.submitContentQuestionMetaForReview(answer.ContentQuestionMetaId).then((res: any) => {
        this.answers[question.ContentQuestionId] = res.data;
        this.originalAnswers[question.ContentQuestionId] = res.data;
        this.submittingAnswer = false;
        this.snackbar.open("Your response submitted for review");
      }).catch(e => {
        if (!e.msg) {
          e.msg = "Something went wrong";
        }
        this.snackbar.open(e.msg);
        this.submittingAnswer = false;
      });
    }
  }

  updateAnswer(event: any, contentQuestionId: any, answer: any) {
    if (this.isAnswerModified) {
      this.updatingAnswer = true;

      var data = answer;
      if (!answer.ContentQuestionMetaId) {
        data = {
          ProgramParticipantId: this.programParticipantId,
          ContentId: this.contentId,
          Explanation: answer.Explanation,
          ContentQuestionId: contentQuestionId,
        };
      }
      this.apiService.updateContentQuestionMeta(data).then((res: any) => {

        this.answers[contentQuestionId] = res.data;
        this.originalAnswers[contentQuestionId] = res.data;

        this.isAnswerModified = false;
        this.updatingAnswer = false;
      }).catch(e => {
        this.updatingAnswer = false;
        this.isAnswerModified = true;
      });
    }
  }

  answerChanged(event: any) {
    this.isAnswerModified = true;
  }

}
