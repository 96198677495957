import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarkCompleteDialogComponent } from '../mark-complete-dialog/mark-complete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'sb-assignment-card',
    templateUrl: './assignment-card.component.html',
    styleUrls: ['./assignment-card.component.scss']
})
export class AssignmentCardComponent {

    @Output() refreshTrigger: EventEmitter<any> = new EventEmitter<any>();
    @Output() viewAssignmentTrigger: EventEmitter<any> = new EventEmitter<any>();
    @Input() isTrainer: boolean = false;
    @Input() type: 'pending' | 'completed' | 'overdue' = 'pending';
    @Input() assignment!: any;
    markingComplete: boolean = false;

    constructor(private dialog: MatDialog, private apiService: ApiService, private snackbar: MatSnackBar) { }

    getStatusClass(): string {
        switch (this.assignment.status) {
            case 'completed':
                return 'status-completed';
            case 'overdue':
                return 'status-overdue';
            default:
                return 'status-pending';
        }
    }

    formatDueDate(): string {
        return new Date(this.assignment.dueDate).toLocaleDateString();
    }

    viewAssignment(assignment: any) {
        this.viewAssignmentTrigger.emit(assignment);
    }
      

    markComplete(assignment: any) {

        const dialogRef = this.dialog.open(MarkCompleteDialogComponent, {
            width: '500px',
            data: { assignment }
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {

                if (this.isTrainer) {
                    this.markingComplete = true;
                    this.apiService.markAssignmentAsCompletedByTrainer(assignment.Id, result.remarks)
                        .then((response: any) => {
                            if (response.success) {
                                this.snackbar.open('Assignment marked as complete', 'Close', { duration: 3000 });
                                this.markingComplete = false;
                                this.refreshTrigger.emit();
                            }
                        })
                        .catch(error => {
                            this.snackbar.open('Error marking assignment complete', 'Close', { duration: 3000 });
                            this.markingComplete = false;
                        });
                } else {
                    this.markingComplete = true;
                    this.apiService.markAssignmentAsCompletedByParticipant(assignment.Id, result.remarks)
                        .then((response: any) => {
                            if (response.success) {
                                this.snackbar.open('Assignment marked as complete', 'Close', { duration: 3000 });
                                this.markingComplete = false;
                                this.refreshTrigger.emit();
                            }
                        })
                        .catch(error => {
                            this.snackbar.open('Error marking assignment complete', 'Close', { duration: 3000 });
                            this.markingComplete = false;
                        });
                }
            }
        });
    }

}